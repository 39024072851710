<script setup>
const router = useRouter()
const { $gtmTracking } = useNuxtApp()

const props = defineProps({
  genre: {
    type: String,
    default: '',
  },
})

const genreClass = `become-member-banner--${props.genre}`

function becomeMemberClicked() {
  $gtmTracking.becomeMemberClicked('BecomeMemberSection')
  router.push('/aanmelden')
}
</script>

<template>
  <div
    :class="[
      'become-member-banner pt-56 pt-lg-80 pb-64 pb-lg-80 pl-8 pr-8',
      { [genreClass]: genre },
    ]"
  >
    <div class="container text-center mt-lg-16 mb-lg-48">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-9">
          <h2 class="mb-16">
            {{ $label('common.becomeMemberNow.home.title') }}
          </h2>

          <p class="mb-40">
            {{ $label('common.becomeMemberNow.home.subtitle') }}
          </p>

          <p class="become-member-banner__description text-quote mb-40">
            {{ $label('common.becomeMemberNow.home.description') }}
          </p>

          <BaseButton
            data-testid="become-member-btn"
            class="mb-40"
            variant="call-to-action"
            @click.prevent="becomeMemberClicked"
          >
            {{ $label('common.becomeMemberNow.home.button') }}
          </BaseButton>

          <p class="mb-0 text-small fst-italic">
            {{ $label('common.becomeMemberNow.home.description2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.become-member-banner {
  background-color: $grey-01;
}

@include generate-genre-classes(
  'background-color',
  'light',
  'become-member-banner',
  0.5
);

.become-member-banner__description {
  text-align: center;
}
</style>
